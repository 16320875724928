<template lang="pug">
  v-app.split-bg(style='display:flex; justify-content:center; align-items: center; background: #E1FAF1;')
    v-dialog(v-model='dialog' scrollable='' max-width='400px')
    v-dialog(v-model='forgotPassDialog' max-width='400px')
      v-card(style='background:#2A364F;')
        v-card-title.forgotpass-card(style='font-weight: bold; font-size: 20px !important; color:#45ffad;')
          | Recupera tu contraseña
        v-divider(style='border-color: #45ffad;')
        v-card-text(class='text-center')
          p Ingresa tu correo y te enviaremos un link para recuperar tu contraseña
            strong  NO OLVIDES REVISAR LA BANDEJA DE CORREO NO DESEADO
          v-text-field(label='Correo' v-model='emailToRecoverPass')
        v-card-actions
          v-btn(text='' @click='closeRecoverPass' style='color:#fa7672;') CERRAR
          v-btn.send-btn(text='' @click='forgotPass') ENVIAR
    v-dialog(v-model='confirmationDialogForGmail' persistent max-width='400')
      v-card
        v-card-title.text-h5
          | Verificación
        v-divider
        v-card-text Luego de presionar ACEPTAR te enviaremos un correo con un link, debes seleccionarlo para verificar tu cuenta. Si no lo encuentras, revisa en tu bandeja de correo no deseado.
        v-card-actions
          v-spacer(style="text-align: right;")
            v-btn(color='blue darken-1' text='' @click='registerMethod')
              | Aceptar
    v-dialog(v-model='confirmationDialogForHotmail' persistent max-width='400')
      v-card
        v-card-title.text-h5
          | Verificación
        v-divider
        v-card-text Luego de presionar ACEPTAR revisa tu bandeja de
          strong  CORREO NO DESEADO
          |  y selecciona el link que te hemos enviado en un correo para verificar tu cuenta.
        v-card-actions
          v-spacer(style="text-align: right;")
            v-btn(color='blue darken-1' text='' @click='registerMethod')
              | Aceptar
    v-dialog(v-model='confirmationDialogVerify' persistent max-width='400')
      v-card
        v-card-title.text-h5
          | Verificación
        v-divider
        v-card-text Aún no has verificado tu correo, dirígete al email que te hemos enviado y selecciona el link adjunto por favor, si no lo encuentras, revisa la bandeja de correo no deseado. En el caso de no poder verificar tu cuenta puedes recuperar tu contraseña para que se envíe un nuevo correo.
        v-card-actions
          v-spacer(style="text-align: right;")
            v-btn(color='blue darken-1' text='' @click='finishInit')
              | Aceptar
    v-snackbar(:timeout='5000' top='' right='' :color='snackbar.color' v-model='snackbar.show')
      | {{ snackbar.text }}
      v-btn(dark='' text='' @click.native='snackbar.show = false' icon='' style='background: transparent;')
        v-icon mdi-close
    //- v-layout
    v-row(style="max-width: 100%;")
      v-col(cols='12' lg='12' style='display:flex; justify-content:center; align-items: center;')
        v-card(style='background:#1b1b33; padding: 20px 30px; margin: 0; border-radius: 20px;' elevation='8')
          v-row(style='display:flex; justify-content:center;')
            h1.modal-title(v-if='register')
              | BIENVENIDO A INVENTARIX
          //- v-row(style='display:flex; justify-content:center;')
          //-   p.modal-desc(v-if='register' style='margin: 10px;') Inicia con Facebook o Google
          //- v-row(v-if='register')
          //-   v-col(cols='12' sm='6' style='display:flex; justify-content:center;')
          //-     v-btn.facebook-btn(medium style='color: white; background: #46B2FF; width: 100%; margin: 0px;' elevation='3' @click='socialLogin("facebook")')
          //-       v-icon(large style='color: #0e8ff1;') mdi-facebook
          //-       strong(style='color:#1b1b33;')
          //-         | INGRESA CON FACEBOOK
          //-   v-col(cols='12' sm='6' style='display:flex; justify-content:center;')
          //-     v-btn.google-btn(medium style='color: white; background: #FF7672; width: 100%; margin: 0px;' elevation='3' @click='socialLogin("google")')
          //-       v-icon(large style='color: #e94235;') mdi-google
          //-       strong(style='color:#1b1b33;')
          //-         | INGRESA CON GOOGLE
          p.modal-desc(v-if='register' style='margin: 10px; display: flex; justify-content: center;') Inicia sesión con tu correo y contraseña
          //- .input-block
          v-form(v-if='register' ref='formLogin' v-model='validLogin' lazy-validation='')
            v-text-field(:rules='emailRules' append-icon='mdi-account' name='login' label='Correo de usuario' type='text' v-model='loginData.username' placeholder=' ' @keyup.enter='login(true)' style="color:white !important;")
            v-text-field#logPassword(:rules='[rules.required, rules.min]' :append-icon="showPassLogin ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassLogin = !showPassLogin" name='password' label='Contraseña' placeholder=' ' :type="showPassLogin ? 'text' : 'password'" v-model='loginData.password' @keyup.enter='login(true)')
          v-form(v-if='!register' ref='formRegister' v-model='validRegister' lazy-validation='')
            v-row
              v-col(cols='12')
                v-text-field(:rules='emailRules' append-icon='mdi-email' name='login' label='Correo' type='text' v-model='registerData.email')
            v-row
              v-col(cols='12' md='6')
                v-text-field(:rules='[rules.required]' append-icon='mdi-account-plus' name='login' label='Nombres' type='text' v-model='registerData.firstName')
              v-col(cols='12' md='6')
                v-text-field(:rules='[rules.required]' append-icon='mdi-account-plus' name='login' label='Apellidos' type='text' v-model='registerData.lastName')
            v-row
              v-col(cols='12' md='6')
                v-text-field#regPassword(:rules='[rules.required, rules.minPassword]' :append-icon="showPassReg ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassReg = !showPassReg" name='password' label='Contraseña' :type="showPassReg ? 'text' : 'password'" v-model='registerData.password' hint="Tu contraseña es válida!")
              v-col(cols='12' md='6')
                v-text-field#confirmPassword(:rules='[rules.required, rules.minPassword]' :append-icon="showPassConfirm ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassConfirm = !showPassConfirm" name='confirmPassword' label='Confirmar Contraseña' :type="showPassConfirm ? 'text' : 'password'" v-model='passwordConfirm')
          div(v-if='register')
            .modal-buttons
              v-row
                v-col(cols="12" md="7" style="text-align: start; display:flex; align-items:center;")
                  a(@click='forgotPassDialog = true' style='width: 100%; color: #00FFAD ; text-decoration: underline; font-size:16px;')
                    | Olvidaste la contraseña?
                v-col(cols="12" md="5" style='text-align: center;')
                  v-btn.login-btn(medium :disabled="loading" :loading='loading' style="width: 100%;" elevation='3' @click='login(true)')
                    strong(style='color:#1b1b33;')
                      | INICIAR SESIÓN
            p.sign-up(style="margin-top: 15px;")
              | ¿Aún no tienes una cuenta?
              a(@click='changeRegister(false)' style='color: #00FFAD; text-decoration: underline;')
                |  Quiero registrarme
          div(v-if='!register')
            .modal-buttons(style='display:flex; justify-content:center;')
              v-btn.login-btn(:disabled="loading" @click='registerMethod' :loading='loading' style="width: 100%; background:#00FFAD; color: #1b1b33;")
                strong
                  | REGISTRARSE
            p(style="margin-top: 20px; text-align: center; font-size:16px;")
              a(@click='$router.replace("/termsUse")' style='color: #00FFAD; text-decoration: underline;')
                |  TÉRMINOS Y CONDICIONES DE USO
            p(style="text-align: center; font-size:16px;")
              | ¿Ya tienes una cuenta?
              a(@click='changeRegister(true)' style='color: #00FFAD; text-decoration: underline;')
                |  Inicia sesión
          v-row
            v-col(style="text-align: center;")
              v-btn(rounded medium :loading='loading' elevation='3' style='background:transparent; border: 1px solid #45ffad;' @click='goBackLanding')
                v-icon(style='color:#45ffad;')
                  | mdi-keyboard-backspace
                span(style='padding-left:5px;')
                  strong(style='color:#45ffad;')
                    | REGRESAR A INICIO
      //- //- v-col(cols='12' lg='6' style='padding:0px; display:flex; place-items:center;' v-if='$vuetify.breakpoint.lg')
      //-   v-img(src='@/assets/inventory-login.png' alt='Imagen de login de Inventarix')
</template>

<script>
  // import firebase from 'firebase'
  import moment from 'moment'
  import firebase from 'firebase/app'
  import 'firebase/database'
  import 'firebase/auth'
  // import moment from 'moment'
  var functions = require('../../functions/functions')

  export default {
    data: () => ({
      emailToRecoverPass: '',
      dialog: false,
      currentDay: new Date().toString().substr(4, 12),
      forgotPassDialog: false,
      confirmationDialogVerify: false,
      confirmationDialogForHotmail: false,
      confirmationDialogForGmail: false,
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
      validRegister: true,
      loading: false,
      passwordConfirm: '',
      showPassConfirm: false,
      showPassReg: false,
      showPassLogin: false,
      registerData: {
        id: null,
        username: '',
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        persona: {
          id: null,
        },
        tipoCuenta: null,
      },
      loginData: {
        grant_type: 'password',
        username: '',
        password: '',
      },
      register: true,
      validLogin: true,
      rules: {
        required: value => !!value || 'Contraseña requerida',
        min: v => v.length >= 8 || 'Mínimo 8 caracteres',
        minPassword: value => {
          // const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
          const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/
          return (
            pattern.test(value) ||
            'Min. 8 caracteres, 1 letra mayúscula, 1 minúscula y un número'
          )
        },
      },
      emailRules: [
        v => !!v || 'Correo requerido',
        v => /\S+@\S+\.\S+/.test(v) || 'Email inválido',
      ],
    }),
    methods: {
      goBackLanding () {
        this.$router.push('/')
      },
      socialLogin (type) {
        let firebaseProvider
        if (type === 'google') {
          firebaseProvider = new firebase.auth.GoogleAuthProvider()
        } else if (type === 'facebook') {
          firebaseProvider = new firebase.auth.FacebookAuthProvider()
        }

        firebase.auth().signInWithPopup(firebaseProvider)
          .then(result => {
            this.$loading(true)
            const firebaseUser = firebase.auth().currentUser.providerData[0]
            const user = {
              id: result.user.uid,
              name: firebaseUser.displayName,
              mobileNo: firebaseUser.phoneNumber,
              email: firebaseUser.email,
              profileImage: firebaseUser.photoURL,
            }

            this.$store.dispatch('profile/loadProfileFirst', result.user.uid).then(res => {
              if (res) {
                setTimeout(() => {
                  location.reload(true)
                }, 1000)
              } else {
                // tratamiento para user.name
                var userName = ''
                var userLastName = ''
                const userNameSplited = user.name.split(' ')
                switch (userNameSplited.length) {
                  case 2:
                    userName = userNameSplited[0]
                    userLastName = userNameSplited[1]
                    break
                  case 3:
                    userName = userNameSplited[0] + userNameSplited[1]
                    userLastName = userNameSplited[2]
                    break
                  case 4:
                    userName = userNameSplited[0] + userNameSplited[1]
                    userLastName = userNameSplited[2] + userNameSplited[3]
                    break
                  default:
                    userName = userNameSplited[0]
                    userLastName = ''
                    break
                }
                const passwordFac = functions.randomString(12, 16)
                this.$store.dispatch('facturacion/adminLoginFac').then(res => {
                  if (res) {
                    let userSend = firebaseUser.email
                    if (userSend.length > 20) {
                      userSend = userSend.substr(0, 19).toLowerCase()
                    }
                    const dataUser = {
                      username: userSend,
                      password: passwordFac,
                      rol: 'ROLE_USER',
                      active: true,
                    }
                    const dataSend = {
                      body: dataUser,
                      token: res,
                    }
                    this.$store.dispatch('facturacion/createUser', dataSend).then(res => {
                      if (res) {
                        // se envia un correo de verificacion
                        this.registerData.tipoCuenta = {
                          name: 'personal',
                          code: '001',
                          plan: 'free',
                          suscription: 'monthly',
                          suscriptionDate: moment(new Date()).format('YYYY-MM-DD'),
                        }
                        const accounts = []
                        accounts.push(this.registerData.tipoCuenta)
                        firebase.database().ref(`users/${user.id}`).set({
                          email: user.email,
                          firstName: userName,
                          lastName: userLastName,
                          create: this.currentDay,
                          status: true,
                          accounts: accounts,
                          selectAccount: accounts[0].name,
                          valuidfac: passwordFac,
                        }).then(res => {
                          firebase.database().ref(`iva/${user.id}`).set(
                            '12',
                          )
                          this.snackbar = {
                            show: true,
                            color: 'green',
                            text: 'Inicio de sesión correcto.',
                          }
                          setTimeout(() => {
                            location.reload(true)
                          }, 1000)
                        })
                      } else {
                        this.loading = false
                        this.$loading(false)
                        this.snackbar = {
                          show: true,
                          color: 'red',
                          text: 'Error al momento de crear el usuario.',
                        }
                      }
                    }, reject => {
                      if (reject) {
                        if (reject.response) {
                          if (reject.response.data) {
                            if (reject.response.data.result) {
                              if (reject.response.data.result.message) {
                                if (reject.response.data.result.message.includes('existe')) {
                                  this.loading = false
                                  this.$loading(false)
                                  this.snackbar = {
                                    show: true,
                                    color: 'red',
                                    text: 'Usuario ya registrado.',
                                  }
                                } else {
                                  this.loading = false
                                  this.$loading(false)
                                  this.snackbar = {
                                    show: true,
                                    color: 'red',
                                    text: reject.response.data.result.message,
                                  }
                                }
                              }
                            } else {
                              this.loading = false
                              this.$loading(false)
                              this.snackbar = {
                                show: true,
                                color: 'red',
                                text: 'Error al momento de crear el usuario',
                              }
                            }
                          } else {
                            this.loading = false
                            this.$loading(false)
                            this.snackbar = {
                              show: true,
                              color: 'red',
                              text: 'Error al momento de crear el usuario',
                            }
                          }
                        } else {
                          this.loading = false
                          this.$loading(false)
                          this.snackbar = {
                            show: true,
                            color: 'red',
                            text: 'Error al momento de crear el usuario',
                          }
                        }
                      } else {
                        this.loading = false
                        this.$loading(false)
                        this.snackbar = {
                          show: true,
                          color: 'red',
                          text: 'Error al momento de crear el usuario',
                        }
                      }
                    })
                  } else {
                    this.loading = false
                    this.$loading(false)
                    this.snackbar = {
                      show: true,
                      color: 'red',
                      text: 'Error al momento de crear el usuario',
                    }
                  }
                }, reject => {
                  this.loading = false
                  this.$loading(false)
                  this.snackbar = {
                    show: true,
                    color: 'red',
                    text: 'Error al momento de crear el usuario',
                  }
                })
              }
            })
          }).catch(error => {
            this.$loading(false)
            if (error.code === 'auth/account-exists-with-different-credential') {
              this.snackbar = {
                show: true,
                color: 'red',
                text: 'Ya existe una cuenta registrada con su correo, por favor ingrese con correo y contraseña',
              }
            } else {
              this.snackbar = {
                show: true,
                color: 'red',
                text: `Error al autenticarse con ${type} por favor, intentar de nuevo, si los problemas persisten, revisar su conexion de internet`,
              }
            }
          })
      },
      closeRecoverPass () {
        this.forgotPassDialog = false
        this.emailToRecoverPass = ''
      },
      forgotPass () {
        this.emailToRecoverPass = this.emailToRecoverPass.trim()
        if (this.emailToRecoverPass !== '') {
          firebase.auth().sendPasswordResetEmail(this.emailToRecoverPass).then(() => {
            this.snackbar = {
              show: true,
              color: 'green',
              text: 'Correo enviado con éxito',
            }
            firebase.auth().signOut()
            this.forgotPassDialog = false
          }).catch(() => {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Su correo no está registrado',
            }
          })
        } else {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'El campo correo no puede estar vacío',
          }
        }
      },
      finishInit () {
        this.loading = false
        this.$loading(false)
        this.confirmationDialogVerify = false
        firebase.auth().signOut()
      },
      selectDialogToShow () {
        // proceso para mostrar los dialogos de verificacion para cuentas hotmail y gmail
        if (this.$refs.formRegister.validate()) {
          if (this.registerData.email.includes('gmail')) {
            this.confirmationDialogForGmail = true
          } else {
            this.confirmationDialogForHotmail = true
          }
        }
      },
      registerMethod () {
        this.confirmationDialogForGmail = false
        this.confirmationDialogForHotmail = false
        this.passwordConfirm = this.passwordConfirm.trim()
        this.registerData.password = this.registerData.password.trim()
        if (this.$refs.formRegister.validate()) {
          if (this.passwordConfirm === this.registerData.password) {
            this.loading = true
            this.$loading(true)
            const passwordFac = functions.randomString(12, 16)
            this.registerData.email = this.registerData.email.trim()
            this.$store.dispatch('facturacion/adminLoginFac').then(res => {
              if (res) {
                let userSend = this.registerData.email
                if (userSend.length > 20) {
                  userSend = userSend.substr(0, 19).toLowerCase()
                }
                const dataUser = {
                  username: userSend,
                  password: passwordFac,
                  rol: 'ROLE_USER',
                  active: true,
                }
                const dataSend = {
                  body: dataUser,
                  token: res,
                }
                this.$store.dispatch('facturacion/createUser', dataSend).then(res => {
                  if (res) {
                    firebase.auth().createUserWithEmailAndPassword(this.registerData.email, this.registerData.password).then((user) => {
                      // se envia un correo de verificacion
                      this.registerData.tipoCuenta = {
                        name: 'personal',
                        code: '001',
                        plan: 'free',
                        suscription: 'monthly',
                        suscriptionDate: moment(new Date()).format('YYYY-MM-DD'),
                      }
                      const accounts = []
                      accounts.push(this.registerData.tipoCuenta)
                      // firebase.auth().currentUser.sendEmailVerification().then(() => {
                      // })
                      firebase.database().ref(`users/${user.user.uid}`).set({
                        email: this.registerData.email,
                        firstName: this.registerData.firstName,
                        lastName: this.registerData.lastName,
                        create: this.currentDay,
                        status: true,
                        accounts: accounts,
                        selectAccount: accounts[0].name,
                        valuidfac: passwordFac,
                      }).then(res => {
                        firebase.database().ref(`iva/${user.user.uid}`).set(
                          '12',
                        )
                        this.snackbar = {
                          show: true,
                          color: 'green',
                          text: 'Usuario agregado correctamente.',
                        }
                        this.loginData.username = this.registerData.email
                        this.loginData.password = passwordFac
                        try {
                          const logged = firebase.auth().signInWithEmailAndPassword(this.loginData.username, this.loginData.password)
                          if (logged) {
                            const currentUser = firebase.auth().currentUser
                            firebase.database().ref(`users/${currentUser.uid}`).once('value', (snapshot) => {
                              const user = snapshot.val()
                              user.uid = currentUser.uid
                              setTimeout(() => {
                                location.reload(true)
                              }, 1000)
                            }, () => {
                              this.dialog = false
                              this.loading = false
                              this.$loading(false)
                              this.snackbar = {
                                show: true,
                                color: 'red',
                                text: 'Error al obtener los datos del usuario',
                              }
                            })
                          }
                        } catch (e) {
                          this.dialog = false
                          this.loading = false
                          this.$loading(false)
                          this.snackbar = {
                            show: true,
                            color: 'red',
                            text: 'Revisa los datos ingresados o tu conexión de internet',
                          }
                        }
                      })
                    }, () => {
                      this.loading = false
                      this.$loading(false)
                      this.snackbar = {
                        show: true,
                        color: 'red',
                        text: 'Tu correo ya está registrado, si no recuerdas tu contraseña, regresa a la pagina anterior y presiona en Olvidaste la contraseña?',
                      }
                    })
                  } else {
                    this.loading = false
                    this.$loading(false)
                    this.snackbar = {
                      show: true,
                      color: 'red',
                      text: 'Error al momento de crear el usuario.',
                    }
                  }
                }, reject => {
                  if (reject) {
                    if (reject.response) {
                      if (reject.response.data) {
                        if (reject.response.data.result) {
                          if (reject.response.data.result.message) {
                            if (reject.response.data.result.message.includes('existe')) {
                              this.loading = false
                              this.$loading(false)
                              this.snackbar = {
                                show: true,
                                color: 'red',
                                text: 'Usuario ya registrado.',
                              }
                            } else {
                              this.loading = false
                              this.$loading(false)
                              this.snackbar = {
                                show: true,
                                color: 'red',
                                text: reject.response.data.result.message,
                              }
                            }
                          }
                        } else {
                          this.loading = false
                          this.$loading(false)
                          this.snackbar = {
                            show: true,
                            color: 'red',
                            text: 'Error al momento de crear el usuario.',
                          }
                        }
                      } else {
                        this.loading = false
                        this.$loading(false)
                        this.snackbar = {
                          show: true,
                          color: 'red',
                          text: 'Error al momento de crear el usuario.',
                        }
                      }
                    } else {
                      this.loading = false
                      this.$loading(false)
                      this.snackbar = {
                        show: true,
                        color: 'red',
                        text: 'Error al momento de crear el usuario.',
                      }
                    }
                  } else {
                    this.loading = false
                    this.$loading(false)
                    this.snackbar = {
                      show: true,
                      color: 'red',
                      text: 'Error al momento de crear el usuario.',
                    }
                  }
                })
              } else {
                this.loading = false
                this.$loading(false)
                this.snackbar = {
                  show: true,
                  color: 'red',
                  text: 'Error al momento de crear el usuario.',
                }
              }
            }, reject => {
              this.loading = false
              this.$loading(false)
              this.snackbar = {
                show: true,
                color: 'red',
                text: 'Error al momento de crear el usuario.',
              }
            })
          } else {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'La contraseña y su confirmación deben coincidir',
            }
          }
        }
      },
      changeRegister (ban) {
        this.register = ban
      },
      async login (init) {
        if (init) {
          if (!this.$refs.formLogin.validate()) {
            return
          }
        }
        this.loginData.username = this.loginData.username.trim()
        this.dialog = true
        this.loading = true
        this.$loading(true)
        const passwordTrim = this.loginData.password.trim()
        try {
          const logged = await firebase.auth().signInWithEmailAndPassword(this.loginData.username, passwordTrim)
          // const userVerified = firebase.auth().currentUser.emailVerified
          // if (userVerified === false) { // el usuario aun no ha verificado su correo
          //   this.confirmationDialogVerify = true
          //   this.$loading(false)
          // }
          if (logged) {
            const currentUser = firebase.auth().currentUser
            firebase.database().ref(`users/${currentUser.uid}`).once('value', (snapshot) => {
              const user = snapshot.val()
              user.uid = currentUser.uid
              // this.$store.dispatch('setLocalStorageData', { item: 'user', obj: user })
              // this.$store.dispatch('users/setDataUser', user)
              // const res = snapshot.val()
              // const name = {
              //   firstName: res.firstName,
              //   lastName: res.lastName,
              // }

              // localStorage.setItem('name', JSON.stringify(name))
              setTimeout(() => {
                location.reload(true)
              }, 1000)
            }, () => {
              this.dialog = false
              this.loading = false
              this.$loading(false)
              this.snackbar = {
                show: true,
                color: 'red',
                text: 'Error al obtener los datos del usuario',
              }
            })
          }
        } catch (e) {
          this.dialog = false
          this.loading = false
          this.$loading(false)
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Revisa los datos ingresados o tu conexión de internet',
          }
        }
      },
    },
  }
</script>

<style lang="scss">
.send-btn{
  background: #45ffad;
  color:#2a374f;
}
.v-btn__content {
  font-family: 'ceraProBlack', sans-serif !important;
}
.forgotpass-card{
  font-family: 'ceraProBlack', sans-serif !important;
}
.mdi-account-plus::before {
  color: #45ffad;
}
.mdi-email::before{
  color: #45ffad;
}
.mdi-account::before{
  color: #45ffad;
}
.mdi-eye-off::before{
  color: #45ffad;
}
.v-application--is-ltr .v-text-field .v-label {
  color: #45ffad;
}
.login-btn{
  background-color: #45ffad !important;
  color:#1b1b33;
  font-family: 'ceraProBlack', sans-serif !important;
  font-size: 15px !important;
  // border: 1px solid #45ffad;
}
.google-btn {
  font-family: 'ceraProBlack', sans-serif !important;
}
.facebook-btn{
  font-family: 'ceraProBlack', sans-serif !important;
}
p {
  color: #ffff;
  font-family: 'ceraProRegular', sans-serif !important;
  font-size:16px;
}
a {
  color: #ffff;
  font-family: 'ceraProRegular', sans-serif !important;
}
h3 {
  font-family: 'ceraProRegular', sans-serif !important;
}
// b, strong {
//   font-family: 'ceraProBlack', sans-serif !important;
//   font-size: 14px;
// }
.mdi-google::before {
  font-size: 25px;
}
.mdi-facebook::before {
  font-size: 25px;
}
.modal {

  &-title {
    text-align:center;
    font-size: 26px;
    margin: 0;
    font-weight: 400;
    color: #45ffad;
  }

  &-desc {
    margin: 6px 0 30px 0;
    text-align: center;
  }

  &-button {
    color: darken(#8c7569, 5%);
    // font-family: "Nunito", sans-serif;
    font-family: 'ceraProRegular', sans-serif;
    font-size: 18px;
    cursor: pointer;
    border: 0;
    outline: 0;
    padding: 10px 40px;
    border-radius: 30px;
    background: rgb(255, 255, 255);
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.16);
    transition: 0.3s;

    &:hover {
      border-color: rgba(255, 255, 255, 0.2);
      background: rgba(#fff, 0.8);
    }
  }

  &-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;

    // a {
    //   color: rgba(#333, 0.6);
    //   font-size: 14px;
    // }
  }
}

.sign-up {
  margin: 60px 0 0;
  font-size: 16px;
  text-align: center;
}

// .input-button {
//   padding: 8px 12px;
//   outline: none;
//   border: 0;
//   color: #fff;
//   border-radius: 4px;
//   background: #8c7569;
//   // font-family: "Nunito", sans-serif;
//   font-family: 'ceraProRegular', sans-serif;
//   transition: 0.3s;
//   cursor: pointer;

//   &:hover {
//     background: 'primary';
//   }
// }
.v-application--wrap{
  display: flex;
  justify-content: center;
  align-items: center;
}
.theme--light.v-input input, .theme--light.v-input textarea {
  color: white !important;
}
.theme--light.v-input {
  font-family: 'ceraProRegular';
}
.mdi-eye::before {
   color: #45ffad;
}
</style>
